import {
  ClientQuestionId,
  QuestionType,
  ClientQuestionChoiceId,
  SavedSurveyItem,
  VotingChoice,
  RandomizedSurveyItems,
} from './question';
import { ClientQuestionSetId } from './question-set';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';

// A little util enum to help with KeyPress key values
export enum KeyPressKey {
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  SPACE = ' ',
}

// Mismatch between React hook form and React Bootstrap so need to cast as React Bootstrap Ref (https://github.com/react-hook-form/react-hook-form/issues/598)
export type RBRef = string & ((ref: Element | null) => void);

export interface MCChoice {
  readonly id: VotingChoice['choice']['id'];
}
export interface MCChoiceComment {
  readonly id: string | null;
  readonly comment: string | null;
}
export interface PointAllocationChoice {
  readonly id: VotingChoice['choice']['id'];
  readonly point: number;
}
export interface GridChoice {
  readonly rowId: ClientQuestionId;
  readonly colId: ClientQuestionChoiceId;
}
export type GridChoiceByRowId = Record<string, string | undefined>;
export interface SurveyLoadedEvents {
  readonly startSurveyInProcessVote: (
    setId: ClientQuestionSetId,
    surveyItems: readonly SavedSurveyItem[]
  ) => void;
  readonly surveySubmitClearVote: (id: ClientQuestionSetId) => void;
  readonly surveySelectMultipleChoice: (
    choiceIds: readonly MCChoice[],
    questionId: ClientQuestionId
  ) => void;
  readonly surveySelectPointAllocation: (
    choiceIds: readonly PointAllocationChoice[],
    questionId: ClientQuestionId
  ) => void;
  readonly surveyUpdateFreeTextComment: (
    comment: string,
    questionId: ClientQuestionId
  ) => void;
  readonly surveyUpdateChoiceComment: (
    comment: string,
    questionId: ClientQuestionId,
    questionChoiceId: ClientQuestionChoiceId
  ) => void;
  readonly surveySelectGridChoice: (surveyGridChoiceArgs: {
    readonly questionId: ClientQuestionId;
    readonly gridChoice: GridChoice;
    readonly numChoices: number;
  }) => void;
  readonly submitVotes: () => Promise<boolean>;
  readonly bookmark: () => void;
  readonly goToFeed: () => void;
  readonly goBack: () => void;
  readonly promptRegistration: () => void;
  readonly cancelInteraction: () => void;
  readonly login: (redirectUrl: string) => void;
  readonly followPublisher: (respId: string | null) => Promise<void>;
  readonly logout: () => Promise<void>;
  readonly setRandomizedSurveyItems: (
    randomizedSurveyItems: RandomizedSurveyItems
  ) => void;
  readonly clearRandomizedSurveyItems: (
    choicesIds: readonly string[] | readonly ClientQuestionId[]
  ) => void;
}

export interface LoadedEvents {
  readonly facebook: {
    readonly shareQuestion: (
      questionId: ClientQuestionId,
      userId: string | null
    ) => void;
  };
  readonly twitter: {
    readonly shareQuestion: (
      questionId: ClientQuestionId,
      userId: string | null
    ) => void;
  };
  readonly selectMultipleChoice: (
    choiceIds: readonly MCChoice[],
    questionId: ClientQuestionId
  ) => void;
  readonly selectPointAllocation: (
    choiceIds: readonly PointAllocationChoice[],
    questionId: ClientQuestionId
  ) => void;
  readonly updateCommentText: (
    comment: string,
    questionId: ClientQuestionId
  ) => void;
  readonly updateChoiceText: (
    comment: string,
    questionId: ClientQuestionId,
    questionChoiceId: ClientQuestionChoiceId
  ) => void;
  readonly submitVote: () => Promise<PollSetVoteResult>;
  readonly submitComment: (comment: string, questionId: ClientQuestionId) => void;
  readonly cancelInteraction: () => void;
  readonly changeAnswer: (c: AnswerChangeability) => void;
  readonly upvoteComment: (commentId: string) => void;
  readonly unUpvoteComment: (commentId: string) => void;
  readonly loadComments: (page: number) => void;
  readonly goToQuestion: (questionId: string, fromOverview?: boolean) => void;
  readonly doneAnsweringQuestions: () => void;
  readonly goToPublishingEntity: (publisherSlug: string) => void; // click on icon
  readonly setShowFullDetails: (showFullDetails: boolean) => void;
  readonly startInProcessVote: (
    questionId: ClientQuestionId,
    questionType: QuestionType
  ) => void;
  readonly setInteraction: (interaction: VotingInteraction) => void;
  //TODO implement these events
  readonly bookmark: () => void;
  readonly goToFeed: () => void;
  //goToCommenter: (id: string) => void; // click on icon in comment?
  readonly goBack: () => void;
  readonly followPublisher: () => Promise<void>;
  readonly promptRegistration: (questionId: ClientQuestionId) => void;
  readonly logout: () => Promise<void>;
  readonly goToSet: () => void;
}

export enum AnswerChangeability {
  CAN_CHANGE_COMMENT = 'CAN_CHANGE_COMMENT',
  CAN_CHANGE_VOTE_AND_COMMENT = 'CAN_CHANGE_VOTE_AND_COMMENT',
}

export enum VotingInteraction {
  SELECTING_CHOICE = 'SELECTING_CHOICE',
  COMMENTING = 'COMMENTING',
  REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL',
  VIEWING_COMMENTS = 'VIEWING_COMMENTS',
  PROMPT_TO_REGISTER = 'PROMPT_TO_REGISTER',
  VOTE_SUCCESSFUL = 'VOTE_SUCCESSFUL',
}

export enum ShareDestination {
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  GOOGLE_PLUS = 'GOOGLE_PLUS',
  EMAIL = 'EMAIL',
  CLIPBOARD = 'CLIPBOARD',
}

// Analytics related type
// it is used for css class name
export enum ANALYTIC_USER_TYPE {
  ADMIN = 'admin',
  RESPONDENT = 'respondent',
}
export enum ANALYTICS_CONTENT_TYPE {
  SURVEY = 'survey',
  POLLS = 'polls',
  LIVE_EVENT = 'live_event',
  CONTENT_POST = 'content_post',
  BUDEGT_SIMULATION = 'budget_sim',
  HOUSING_SIMULATION = 'housing_sim',
  TAXPAYER_RECEIPT = 'taxpayer_receipt',
  PRIORITIZE = 'prioritize',
  OTHER = 'other',
}

export type PollSetVoteResult = PollSetVoteSuccess | PollSetVoteFailure;

export interface PollSetVoteSuccess {
  readonly type: VoteResultType.SUCCESS;
  readonly vote: Gql.VoteForQuestion;
}
export interface PollSetVoteFailure {
  readonly type: VoteResultType.ERROR;
  readonly message: PollSetVoteFailureMessage | string; //string for gql errors
}

export enum PollSetVoteFailureMessage {
  ERROR = "We're sorry; we could not process your vote at this time.",
  NO_CHOICE = 'You attempted to vote without selecing a choice.',
  NO_VOTE = "You attempted to vote but we're not sure what you picked.",
  GRID = 'Cannot vote on grid choice from a poll set.',
  NO_COMMENT = 'You attempted to vote without writing a comment.', //for free text
}

export enum VoteResultType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}
export enum LoadableCommentsStatus {
  LOADING = 'LOADING',
  PARTIALLY_LOADED = 'PARTIALLY_LOADED',
  FULLY_LOADED = 'FULLY_LOADED',
  ERROR = 'ERROR',
}

export interface LoadableComment<T> {
  readonly type: LoadableCommentsStatus;
  readonly loadedQuestion: T;
  readonly message?: string;
}

export interface GqlComment {
  readonly id: string;
  readonly comment: string;
  readonly commenter: Commenter;
  readonly commenterChoice: { readonly id: ClientQuestionChoiceId } | null;
  readonly upvoteCount: number;
  readonly commentDate: {
    readonly raw: number;
  };
}

interface Commenter {
  readonly id: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly postAnonymously: boolean;
  readonly avatarUrl: string | null;
}

export interface VotingComment {
  readonly id: string;
  readonly commenterChoiceId: string | null;
  readonly comment: string;
  readonly commenterId: string;
  readonly commenterFirstName: string | null;
  readonly commenterLastName: string | null;
  readonly commenterImageUrl: string | null;
  readonly upvoteCount: number;
  readonly isOwnComment?: boolean;
  readonly updatedAt: Date;
}

export interface Choice {
  readonly id: string;
  readonly text: string;
}
