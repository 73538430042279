import * as React from 'react';
import { Snackbar } from '@mui/material';
import { useCurrentTime } from 'client/shared/hooks';
import { Well, WellType } from '../base';
import { ERROR_MESSAGE_COPY } from './copy';
import { MailTo } from '../mail-to';
import { BUG_REPORT_EMAIL } from 'core';

export interface Props {
  readonly error?: readonly [Date, String];
}

export const ErrorMessageDisplay: React.FC<Props> = (p) => {
  const now = useCurrentTime({ frequencyMillis: 1000 * 5 });
  return p.error ? (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      className="pn-error-message-display"
      open={now.getTime() - p.error[0].getTime() < 5000}
    >
      {/* https://stackoverflow.com/questions/74862197/typeerror-cannot-read-properties-of-null-reading-scrolltop-material-ui
      TODO: should prefer <SnackbarContent /> */}
      <div>
        <Well type={WellType.ERROR}>
          <div className="d-flex">
            <div className="mr-2">{ERROR_MESSAGE_COPY.error}</div>
            <MailTo
              body={`The following Error was encountered: ${p.error[1]}
\n\nPage: ${window.location.href}
User Agent: ${window.navigator.userAgent}
Time: ${p.error[0]}`}
              email={BUG_REPORT_EMAIL}
              subject={ERROR_MESSAGE_COPY.reportSubject}
            >
              {ERROR_MESSAGE_COPY.report}
            </MailTo>
          </div>
        </Well>
      </div>
    </Snackbar>
  ) : null;
};
