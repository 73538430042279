import { Lens } from '@atomic-object/lenses';

import {
  Maybe,
  Environmentable,
  AppliedEnvironment,
  Cancelable,
  FunctionsOnly,
  SafeDictionary,
  UMaybe,
  Require,
  SoftRequire,
} from './types';
import _ from 'lodash';
import { ApiError } from 'core/errors';

export { unionOfEnum, unionHelper, unionOfStrings } from './union';
export { Result } from './result';
export * from './types';

export const keyByNullValue = '__null__' as const;

/* tslint:disable */
export interface AssertAssignable<T, _U extends T> {}
/* tslint:enable */

export function swallowPromise(_maybePromise: Promise<any> | void): void {}

export function swallowExpression(_expr: any): void {}

export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function targetReducer<T, U>(
  reducer: (arg: U, action: any) => U,
  lens: Lens<T, U>
): (arg: T, action: any) => T {
  return (arg: T, action: any) => lens.set(arg, reducer(lens.get(arg), action));
}

/**
 * Perform a map with asynchronous callbacks, but ensure mapping is done in serial
 * order (i.e. wait for async iteration on element 1 before beginning async iteration
 * on element 2)
 * @param coll collection to map over
 * @param mapFn map function - must return a promise
 * @returns a promise of an array of the mapped type
 */
export async function serialAsyncMap<T, U>(
  coll: ArrayLike<T>,
  mapFn: (t: T, idx: number) => Promise<U>
): Promise<readonly U[]> {
  const output: U[] = [];
  for (let idx = 0; idx < coll.length; idx++) {
    output.push(await mapFn(coll[idx], idx));
  }
  return output;
}

export function pluck<T extends object, U extends keyof T>(
  array: ArrayLike<T>,
  ...keys: readonly U[]
): readonly Pick<T, U>[] {
  return _.map(array, (elem) => _.pick<T, U>(elem, keys));
}

export function prune<T extends {}>(t: T): T {
  return _.omitBy(t, _.isUndefined) as any;
}

export function pruneRecursive<T extends {}>(t: T): T {
  if (t === null || typeof t !== 'object') {
    return t;
  } else if (_.isArray(t)) {
    return _.compact(t).map((v) =>
      typeof v === 'object' ? pruneRecursive(v) : v
    ) as any;
  }
  const pruned = prune(t);
  return _.mapValues(pruned, pruneRecursive) as T;
}

export function toImmutable<T>(t: T): Readonly<T> {
  return t;
}

// eslint-disable-next-line functional/prefer-readonly-type
export function toMutable<T>(a: ReadonlyArray<T>): T[] {
  return Array.from(a);
}

export function typedKeys<T extends Record<string, any>>(object: T) {
  return Object.keys(object) as readonly (keyof T & string)[];
}

export function compose<T, U, V>(f: (t: T) => U, g: (u: U) => V): (t: T) => V {
  return (x) => g(f(x));
}

export function as<T>(t: T): T {
  return t;
}

export function zip2<T, U, V = readonly [T, U]>(
  tValues: ArrayLike<T>,
  uValues: ArrayLike<U>,
  f?: (t: T, u: U) => V
): ReadonlyArray<V> {
  let ts = tValues;
  let us = uValues;
  if (ts.length > us.length) {
    ts = _.take(ts, us.length);
  } else if (ts.length < us.length) {
    us = _.take(us, ts.length);
  }
  const fn = f || ((t: T, u: U) => [t, u] as any as V);
  return _.map(ts, (t, i) => {
    const u = us[i];
    return fn(t, u);
  });
}

export function cartesianWith<X, Y, Z>(
  xs: readonly X[],
  ys: readonly Y[],
  fn: (x: X, y: Y) => Z
): readonly Z[] {
  // If there is nothing to cross with in either dimension, return empty array
  if (xs.length === 0 || ys.length === 0) {
    return [];
  }
  return xs.flatMap((x) => ys.map((y) => fn(x, y)));
}

export function cartesian<X, Y>(
  xs: readonly X[],
  ys: readonly Y[]
): readonly (readonly [X, Y])[] {
  return cartesianWith(xs, ys, (x, y) => [x, y]);
}

export function cartesian3<X, Y, Z>(
  xs: readonly X[],
  ys: readonly Y[],
  zs: readonly Z[]
): readonly (readonly [X, Y, Z])[] {
  const cartesian2 = cartesian(xs, ys);
  return cartesianWith(cartesian2, zs, ([x, y], z) => [x, y, z]);
}

export function getObjectKeys<T extends Record<string, any>>(object: T) {
  return Object.keys(object) as readonly (keyof T & string)[];
}

type NN<T> = NonNullable<T>;

/**
 * Create a function that traverses up to four properties. Useful in map() scenarios
 * @param k a property of type T to index into using the generated function
 * @param k2 a property of type T[k] to index into using the generated function
 * @return a function which can traverse properties.
 *
 * @remarks Example
 * instead of
```
x = arr.map(v => v.propA.propB.propC)
```
 * you can do
```
x = arr.map(prop('propA', 'propB', 'propC'));
```
And of course it's all type safe.
 */
export function prop<T, K extends keyof T>(k: K): (t: T) => T[K];
export function prop<T, K extends keyof T, K2 extends keyof T[K]>(
  k: K,
  k2: K2
): (t: T) => T[K][K2];
export function prop<
  T,
  K extends keyof T,
  K2 extends keyof T[K],
  K3 extends keyof T[K][K2],
>(k: K, k2: K2, k3: K3): (t: T) => T[K][K2][K3];
export function prop<
  T,
  K extends keyof T,
  K2 extends keyof T[K],
  K3 extends keyof T[K][K2],
  K4 extends keyof T[K][K2][K3],
>(k: K, k2: K2, k3: K3, k4: K4): (t: T) => T[K][K2][K3][K4];
export function prop(...keys: readonly string[]): (t: any) => any {
  return (t) => {
    let v = t;
    for (const k of keys) {
      v = v[k];
    }
    return v;
  };
}

/**
 * Create a function that traverses up to four properties, terminating on null or undefined values.
 * Useful in map() scenarios. Think of it like Maybe.bind().
 * @param k a property of type T to index into using the generated function
 * @param k2 a property of type T[k] to index into using the generated function
 * @return a function which can traverse properties.
 *
 * @remarks Example
 * instead of
```
x = arr.map(v => v.propA && v.propA.propB ? v.propA.propB.propC : null)
```
 * you can do
```
x = arr.map(tryProp('propA', 'propB', 'propC'));
```
And of course it's all type safe.
 */
export function tryProp<T extends Object, K extends keyof T>(
  k: K
): (t: T) => NN<T[K]> | null;
export function tryProp<
  T extends Object,
  K extends keyof T,
  K2 extends keyof NN<T[K]>,
>(k: K, k2: K2): (t: T) => NN<T[K]>[K2] | null;
export function tryProp<
  T extends Object,
  K extends keyof T,
  K2 extends keyof NN<T[K]>,
  K3 extends keyof NN<NN<T[K]>[K2]>,
>(k: K, k2: K2, k3: K3): (t: T) => NN<NN<T[K]>[K2]>[K3] | null;
export function tryProp<
  T extends Object,
  K extends keyof T,
  K2 extends keyof NN<T[K]>,
  K3 extends keyof NN<NN<T[K]>[K2]>,
  K4 extends keyof NN<NN<NN<T[K]>[K2]>[K3]>,
>(k: K, k2: K2, k3: K3, k4: K4): (t: T) => NN<NN<NN<NN<T[K]>[K2]>[K3]>[K4]> | null;
export function tryProp(...keys: readonly string[]): (t: any) => any {
  return (t: any) => {
    let v = t || null;
    for (const k of keys) {
      if (v === null) return v;
      v = v[k] || null;
    }
    return v;
  };
}

export function maybeMap<T, U>(t: UMaybe<T>, f: (t: T) => U): Maybe<U> {
  return t ? f(t) : null;
}

/**
 * Return a function bound to a particular object. Useful in cases where you can't do
```
  memberFunc = (args) => {
```
 * @param t the object to bind function k to
 * @param k the name of property which is a function to bind to t
 * @returns a function which can be passed around and won't lose the 'this' you want
 */
export function bind<T, K extends keyof T>(t: T, k: K): T[K] {
  return (t[k] as any).bind(t);
}

/**
 * An attempt to make declaration of (discriminated) union types simpler
 * and more consistent. The optional 3rd param lets you select a different
 * type field.
 */

export type Case<
  T extends string | number,
  U extends {} | undefined = undefined,
  V extends string | number = 'type',
> = U extends undefined ? { readonly [F in V]: T } : { readonly [F in V]: T } & U;

export function Case<T extends string | number>(t: T): Case<T>;
export function Case<T extends string | number, U extends {}>(
  t: T,
  u: U
): Case<T, U>;
export function Case<T extends string | number, U extends {}, V extends string>(
  t: T,
  u: U,
  v: V
): Case<T, U, V>;
export function Case(t: string, u?: any, v: string = 'type'): any {
  return { ...(u as any), [v]: t };
}

export type CaseOf<
  U,
  Type extends string | number,
  V extends string | number,
> = Extract<U, Case<Type, {}, V>>;

export function makeEnvironment<T, E>(
  environmentable: Environmentable<T, E>,
  environment: E
): AppliedEnvironment<FunctionsOnly<T>, E> {
  return _.mapValues(environmentable, (v: any) => {
    if (typeof v === 'function') {
      return _.partial(v, environment);
    } else return v;
  }) as any;
}

/**
 * Make switch statements work more like pattern matching. That is,
 * make a context wherein you can use `return` in a switch statement and
 * not exit the calling function.
 */

export function wrap<T>(fn: () => T): T {
  return fn();
}

export function enumToDict<T>(enumType: T): {
  readonly [k in Extract<keyof T, string>]: T[k];
} {
  return enumType;
}

export function tuple<T, U>(t: T, u: U): readonly [T, U] {
  return [t, u];
}

export function fromPairs<K extends string | symbol | number, T>(
  array: ReadonlyArray<readonly [K, T]>
): Record<K, T> {
  return Object.fromEntries(array) as any;
}

export function makeCancelable<T>(promise: Promise<T>): Cancelable<T> {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise
      .then((val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)))
      .catch((error) =>
        hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
      );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}

/**
 * Takes in an object with null properties and returns a new object with
 * undefined set instead of null and adjusts the type accordingly. Does not
 * work with nested properties.
 * @param t The object to be converted
 * @returns A new object with no nulls
 */
export function nullPropsToUndefined<T extends { readonly [key: string]: any }>(
  t: T
): { readonly [Key in keyof T]?: NonNullable<T[Key]> | undefined } {
  return typedKeys(t).reduce((accumulator, key) => {
    const value = t[key];
    accumulator[key] = value === null ? undefined : value;
    return accumulator;
  }, {} as Partial<T>);
}

export function headAndTail<T>(
  array: readonly T[]
): readonly [T | undefined, readonly T[]] {
  const [head, ...tail] = array;
  return [head, tail];
}

export function determineCaseByHasProp<T extends {}, U extends {}>(
  v: T | U,
  k: Extract<Exclude<keyof U, keyof T>, string>
): v is U {
  return Object.keys(v).includes(k);
}

export function PartialCtor<T, K extends keyof T>(
  base: Pick<T, K>
): (data: Pick<T, Exclude<keyof T, K>>) => T {
  return (data) => ({ ...base, ...data }) as T;
}

export function Ctor<T>(): (data: T) => T {
  return (data) => data;
}

export type GqlInputWannabeUnion<T extends { readonly type: string }> = Omit<
  T,
  'type'
> & {
  readonly type: Exclude<keyof T, 'type'>;
};

export type UnionFromGqlInput<Input extends { readonly type: string }> = {
  readonly [K in Exclude<keyof Input, 'type'>]: { readonly type: K } & {
    readonly [P in K]: NonNullable<Input[P]>;
  };
}[Exclude<keyof Input, 'type'>];

export function gqlInputToUnion<
  TType extends string,
  T extends { readonly type: TType },
>(t: GqlInputWannabeUnion<T>): UnionFromGqlInput<T> {
  return t as any;
}

export function isNotNullOrUndefined<T extends Object>(
  elem: null | undefined | T
): elem is T {
  return elem !== null && elem !== undefined;
}

/**
 * A wrapper function of intersectionBy.
 * this function allows us to have return type.
 *
 * Returned element and return order is determined by the first array
 *
 * @param ts array of arrays
 * @returns Returns the new typed array of intersecting values.
 */
export function typedIntersectionBy<T>(
  ts: readonly (readonly T[])[],
  fn: (t: T) => any
): readonly T[] {
  return _.intersectionBy(...ts, fn);
}

export function assertPresent<T>(t: T): asserts t is NonNullable<T> {
  if (!(t !== null && t !== undefined)) {
    throw new Error('Expected value not present');
  }
}

/**
 * Like the test helper version, throws an error if value isn't present.
 * SEE core/__tests__/helpers/expectation-helper.ts for more
 * @param t
 */
export function expectPresent<T>(t: T | null | undefined): NonNullable<T> {
  assertPresent(t);
  return t;
}

export function asSafeDict<T>(d: _.Dictionary<T>): SafeDictionary<T> {
  return d;
}

export const UUID_REGEX =
  /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;

export function isUuid(v: string) {
  return Boolean(v.match(UUID_REGEX));
}

/**
 * Converts an array of objects into a record. Differs from keyBy in that it
 * allows for value transform - essentially shorthand for
 *
 *  `_.mapValues(_.keyBy(arr, o => o.key), o => o.otherProp)`
 * @param arr the array of objects
 * @param toKey a function to go from the object to the key you wish to use
 * @param toValue a function to go from the object to the value you want in the record
 */
export function arrayToRecord<TArray, TKey extends string | number | symbol, TValue>(
  arr: ArrayLike<TArray>,
  toKey: (t: TArray) => TKey,
  toValue: (t: TArray) => TValue
): Record<TKey, TValue> {
  return _.reduce(
    arr,
    (acc, elem) => {
      return {
        ...acc,
        [toKey(elem)]: toValue(elem),
      };
    },
    {} as Record<TKey, TValue>
  );
}

export function throwFirstError<T>(array: readonly (T | Error)[]): readonly T[] {
  const err = array.find((elem): elem is Error => elem instanceof Error);
  if (err) {
    throw err;
  }
  return array as readonly T[];
}

export function isEnum<TEnum extends string>(
  enumDef: Record<string, TEnum>,
  value: UMaybe<string>
): value is TEnum {
  if (_.isNil(value) || value.trim() === '') return false;

  return Object.values(enumDef).includes(value as TEnum);
}

export function isApiError(object: {}): object is ApiError {
  return typeof object === 'object' && 'statusCode' in object;
}

export function hasProperty<T, K extends keyof T>(
  t: T | Require<T, K>,
  k: K
): t is Require<T, K> {
  return t[k] !== null && t[k] !== undefined;
}

/**
 * Predicate function that will perform type narrowing based on presence of prop.
 * Does *not* remove `?` optional flag on properties, but otherwise quite useful for
 * ```typescript
 * const withEmails = users.filter(requireProperty('email'));
 * // withEmails knows that user.email is non-null
 * ```
 * but note:
 * ```typescript
 * const withEmails = users.filter(requireProperty('invalidProp'));
 * // withEmails is now never[]
 * ```
 */
export function requireProperty<K extends string>(k: K) {
  return <X>(x: X): x is K extends keyof X ? SoftRequire<X, K> : never =>
    hasProperty(x, k as any);
}

export function nullIfNaN<T>(number: T): T | null {
  return typeof number === 'number' && isNaN(number) ? null : number;
}

export function commaSeparatedJoin(
  list: readonly string[],
  args?: {
    readonly useOxfordComma?: boolean;
    readonly separator?: string;
  }
): string {
  if (list.length === 0) {
    return '';
  }
  const separator = args?.separator ?? 'and';
  const isEmptySeparator = separator.length === 0;
  const hasOxfordComma =
    (list.length > 2 && !!args?.useOxfordComma) || isEmptySeparator;
  return `${_.dropRight(list, 1).join(', ')}${
    list.length >= 2
      ? `${hasOxfordComma ? ',' : ''}${isEmptySeparator ? ' ' : ` ${separator} `}`
      : ''
  }${list[list.length - 1]}`;
}

export function makePossessive(noun: string) {
  if (noun.length === 0) {
    return noun;
  }

  if (_.endsWith(noun, 's')) {
    return `${noun}'`;
  }
  return `${noun}'s`;
}

export function median(numbers: readonly number[]): number | undefined {
  if (numbers.length === 0) {
    return undefined;
  }

  const sortedNumbers = _.sortBy(numbers);

  if (sortedNumbers.length % 2 === 0) {
    return (
      (sortedNumbers[sortedNumbers.length / 2] +
        sortedNumbers[sortedNumbers.length / 2 - 1]) /
      2
    );
  } else {
    return sortedNumbers[Math.floor(sortedNumbers.length / 2)];
  }
}

/**
 * Generate all possible combinations given an array of items.
 * Given [1, 2, 3] combinations are:
 * [[1], [2], [3], [1, 2], [2, 3], [1, 3], [1, 2, 3]]
 * @param values array of items to get combinations from
 * @returns an array of arrays, each sub-array containing a possible combination of items from the provided array
 */
export function combinations<T>(
  values: ReadonlyArray<T>
): ReadonlyArray<ReadonlyArray<T>> {
  const combi = [];
  let temp = [];
  const slent = Math.pow(2, values.length);

  for (let i = 0; i < slent; i++) {
    temp = [];
    for (let j = 0; j < values.length; j++) {
      if (i & Math.pow(2, j)) {
        temp.push(values[j]);
      }
    }
    if (temp.length > 0) {
      combi.push(temp);
    }
  }

  combi.sort((a, b) => a.length - b.length);
  return combi;
}

export function extractGql<
  TUnion extends { readonly __typename: string },
  TType extends TUnion['__typename'],
>(
  union: TUnion | null | undefined,
  ttype: TType
): union is Extract<TUnion, { readonly __typename: TType }> {
  if (!union) return false;
  return union.__typename === ttype;
}

export function extractGqlValue<
  TUnion extends { readonly __typename: string },
  TType extends TUnion['__typename'],
>(
  union: TUnion | null | undefined,
  ttype: TType
): Extract<
  TUnion,
  {
    readonly __typename: TType;
  }
> | null {
  return extractGql(union, ttype) ? union : null;
}

export function toTitleCase(str: string): string {
  const exceptions = new Set([
    'a',
    'an',
    'the',
    'and',
    'but',
    'or',
    'for',
    'nor',
    'as',
    'at',
    'by',
    'for',
    'from',
    'in',
    'into',
    'near',
    'of',
    'on',
    'onto',
    'to',
    'with',
  ]);
  return str.replace(/\w\S*/g, (text) => {
    //Retain all caps strings in the case of acronyms
    if (text.match(/[A-Z0-9]+[?.!-+,]*$/g)) {
      return text;
    }
    return exceptions.has(text.toLowerCase())
      ? text.toLowerCase()
      : _.capitalize(text);
  });
}
