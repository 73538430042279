import Fingerprint2 from 'fingerprintjs2sync';
import { SharedIntegrationConfigKeys } from 'config';

import { googleInit } from './google';
import { hubspotInit } from './hubspot';
import { mouseflowInit } from './mouseflow';
import { getDomains, getIntegrationConfigKey, Nonce } from './util';
export { initRollbar } from './rollbar/init-script';
export { initCloudinary } from './cloudinary';
export { getIntegrationConfigKey } from './util';
export { fbInit, fbShare, fbLogin } from './facebook';
export { trackTwitterConversion, initTwitterPixel, twitterShare } from './twitter';
export { initTinyMCE } from './tinyMCE';
export {
  heapInit,
  heapIdentify,
  heapAddUserProperties,
  heapAddEventProperties,
} from './heap';
export { stripeInit } from './stripe';

export function optionalInit<K extends keyof SharedIntegrationConfigKeys, TArgs>(
  fn: (token: string, ...fnArgs: readonly TArgs[]) => void,
  configVar: K,
  ...args: readonly TArgs[]
) {
  const token = getIntegrationConfigKey(configVar);
  if (token && token.length > 0) {
    fn(token, ...args);
  }
}

export function getFingerprint(): string {
  if (process.env.NODE_ENV === 'test') {
    return 'abc123';
  } else {
    return new Fingerprint2().getSync().fprint;
  }
}

export function nonceInit() {
  // Injected into the html by the server
  const nonce = (window as any).POLCO_NONCE as Nonce;
  googleInit(
    {
      trackingId: getIntegrationConfigKey('googleAnalyticsTrackingId'),
      mapApiKey: getIntegrationConfigKey('googleMapsApiKey'),
      gtmId: getIntegrationConfigKey('googleTagManagerId'),
    },
    getDomains(),
    nonce
  );
  mouseflowInit(nonce);
  optionalInit(hubspotInit, 'hubspotPortalId', nonce);
}
